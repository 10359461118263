
import React from 'react';
import { Platform, TouchableNativeFeedback, TouchableWithoutFeedbackProps, TouchableOpacity } from 'react-native';


/**
 * Uses <TouchableNativeFeedback /> on android and <TouchableOpacity /> on other platforms
 */
export default class TouchableCell extends React.Component<TouchableWithoutFeedbackProps> {
    render() {
        return Platform.OS === 'android' 
        ? <TouchableNativeFeedback {...this.props} />
        : <TouchableOpacity {...this.props} />
    }
}                  
