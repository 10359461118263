import { MixedStyleRecord } from "react-native-render-html";


export const tagsStylesDetail:MixedStyleRecord = {
    body: {
      fontSize: 17,
      lineHeight: 26
    }
  };

  export const tagsStylesList:MixedStyleRecord = {
    body: {
      fontSize: 15,
      lineHeight: 20,
      overflow: 'hidden'
    },
    p: {
        height: 40
    },
  };
