import React from 'react';
import { Text, ScrollView, StyleSheet, SafeAreaView, Image, ImageURISource, Platform } from 'react-native';
import Constants from 'expo-constants';
import { computed } from 'mobx'
import { observer } from 'mobx-react';
import { FestivalDownload } from '../models/api';
import ScaledImage from '../ScaledImage';
// import MyMarkdown from '../components/Markdown';

type HomeProps = {
  festival: FestivalDownload
}

@observer
class HomeScreen extends React.Component<HomeProps> {

  @computed get imageSource(): ImageURISource {
    let img = this.props.festival.images[0]
    let src: ImageURISource = {}

    if (img) {
      src.uri = img.url
    }

    return src
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <ScrollView style={styles.scrollView}>
          <Text>laeh</Text>
          <ScaledImage src={this.props.festival.images[0].url} />
          {/* <MyMarkdown style={ markdownStyle }>{this.props.festival.about}</MyMarkdown> */}
        </ScrollView>
      </SafeAreaView>
    )
  }
}

const markdownStyle = {
  body: { 
    fontSize: 16, 
    lineHeight: 24 
  }
}
 
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Constants.statusBarHeight,
    paddingTop: 25
  },
  scrollView: {
    // backgroundColor: 'pink',
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 18,
  },
  image: {
    width: 200,
    height: 100,
    flex: 1
  },
});


/*
const styles = StyleSheet.create({

    scrollView: {
        paddingHorizontal: 20,
    },

    detailImage: {
        flex: 1
    },

    detailHeader: {
        fontSize: 27,
        fontWeight: "bold"
    },

    detailBio: {
        lineHeight: 24,
        fontSize: 16,
        paddingBottom: 48

    },

    setHeader: {

    },

    setText: {

    }
});*/

export default HomeScreen
