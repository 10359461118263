import { observable, action, runInAction, makeObservable } from 'mobx';

export class ErrorStore {
	constructor() {
		makeObservable(this)
	}

	@observable errors: string[] = []

	@action.bound
	addError(message: string) {
		// console.log(`ADDING ERROR ${message}`)
		this.errors.push(message)
		setTimeout(() => {
			runInAction( () => {
				this.errors.shift()
			})
		}, 5000)
	}
}

var errorStore = new ErrorStore()

export default errorStore
