import React from 'react';
import { StyleSheet, ScrollView, Platform } from 'react-native';
import { Text } from 'react-native-elements';
import ScaledImage from '../ScaledImage'
import { ArtistDownload, IOSClientEventResponse, VenueDownload } from '../models/api';
import store from '../stores/MeltdownStore';
import { computed } from 'mobx';
import moment from 'moment';
import MyMarkdown from '../components/Markdown';
import { MixedStyleRecord } from 'react-native-render-html';
import { removeElement, isTag } from 'domutils';
import { tagsStylesDetail } from '../Styles';
import { observer } from 'mobx-react';
  
type ArtistProps = {
    artist: ArtistDownload
}

type EventProps = {
    event: IOSClientEventResponse
}

@observer
class SetInfo extends React.Component<EventProps> {
    @computed get eventTime():string {
        let start = moment.parseZone(this.props.event.start)
  
        return start.format('LT dddd')
    }

    @computed get venue():VenueDownload|undefined {
        return store.festival?.venues?.find( (v) => { return this.props.event.venueId === v.id })
    }

    render() {
        let venue = this.venue

        return (
            <Text>{this.eventTime} @ {venue ? venue.name : 'No Venue'} </Text>
        )
    }
}
  
@observer
export default class ArtistDetail extends React.Component<ArtistProps> {

    @computed get sets():IOSClientEventResponse[] {
        let events = store.festival?.events?.filter( (event) => { return event.artistId === this.props.artist.id })

        console.log(events)
        return events ? events : []
    }

    renderRow = (event:IOSClientEventResponse) => {
        return <SetInfo event={event} />
    }
    render() {
    
        return (
            <ScrollView style={styles.scrollView}>
                {
                    this.props.artist.images && this.props.artist.images[0] 
                    ? <ScaledImage src={this.props.artist.images[0].url} />
                    : <Text>N/A</Text>
                }
                {/* <Text style={styles.detailHeader}>{this.props.artist.name}</Text> */}
                <Text h3>{this.props.artist.name}</Text>
                <MyMarkdown tagsStyles={tagsStylesDetail} source={this.props.artist.bio}/>
                <Text h3>Sets</Text>
                {this.sets.map( (event) => <SetInfo key={event.id} event={event} /> )}
                <Text />
            </ScrollView>
        )
    }
}

const markdownStyle = {
    body: {
        lineHeight: 24,
      fontSize: 16
    }
}

const styles = StyleSheet.create({

    scrollView: {
        paddingHorizontal: 20,
    },

    detailImage: {
        flex: 1
    },

    detailHeader: {
        fontSize: 27,
        fontWeight: "bold"
    },

    setHeader: {

    },

    setText: {

    }
});