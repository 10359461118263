import React, { ReactElement } from 'react';
import { Text, View, StyleSheet, Dimensions, Image, TouchableNativeFeedback, ScrollView, SafeAreaView } from 'react-native';
import { VenueDownload } from '../models/api';
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";
import TouchableCell from '../components/TouchableCell'
import MyMarkdown from '../components/Markdown';
import { MixedStyleRecord } from 'react-native-render-html';
import { removeElement, isTag } from 'domutils';
import { tagsStylesList } from '../Styles';
import { observer } from 'mobx-react';

let pNum = 1
function onElement(element: any) {
    // Remove the first two children of an ol tag.
    if (element.tagName === 'p') {
        // console.log(`found paragraph tag #${pNum}`)

        if (pNum > 1) {
            // console.log(`removing paragraph #${pNum}`)
            removeElement(element)
        }

        pNum++
    }
}

const domVisitors = {
    onElement: onElement
};


type VenueListItemProps = {
    onSelect: (venue: VenueDownload) => void
    venue: VenueDownload
}

type VenueListProps = {
    onSelect: (venue: VenueDownload) => void
    venues: VenueDownload[]
}

@observer
class VenueListItem extends React.Component<VenueListItemProps> {
    resetPnum(): ReactElement {
        pNum = 1
        return <></>
    }
    render() {
        return (
            <View>
                <View style={styles.cell}>
                    <TouchableCell onPress={() => { this.props.onSelect(this.props.venue) }}>
                        <View style={styles.cellContents}>
                            <Image style={styles.cellImage} source={{ width: 100, height: 100, uri: this.props.venue.images[0].url }} />
                            <View style={styles.cellText}>
                                <Text style={styles.cellHeader}>{this.props.venue.name}</Text>
                                {this.resetPnum()}
                                <MyMarkdown tagsStyles={tagsStylesList} domVisitors={domVisitors} source={this.props.venue.bio} />
                                {/* <Text numberOfLines={2} ellipsizeMode='tail'>{this.props.venue.bio}</Text> */}
                            </View>
                        </View>
                    </TouchableCell>
                </View>
            </View>)
    }
}

/***
*/
@observer
export default class VenueList extends React.Component<VenueListProps> {
    width = Dimensions.get("window").width

    dataProvider = new DataProvider((r1, r2) => {
        return r1 !== r2;
    });

    layoutProvider = new LayoutProvider(
        _index => {
            return 0 // all have same type 0
        },
        (_type, dim) => {
            dim.width = this.width;
            dim.height = 110;
        }
    );

    rowRenderer(_type: React.ReactText, venue: VenueDownload, _index: number) {
        return <VenueListItem venue={venue} onSelect={this.props.onSelect} />
    }

    render() {
        return (                
        <SafeAreaView style={styles.container}>{
 
                    this.props.venues ?
                    <RecyclerListView
                          style={{flex:1}}
                            disableRecycling={true}
                            layoutProvider={this.layoutProvider}
                            dataProvider={this.dataProvider.cloneWithRows(this.props.venues)}
                            rowRenderer={this.rowRenderer.bind(this)} />
                        :
                        <Text>Missing required props</Text>
        }</SafeAreaView>

        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: "green",
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: 0 
    },

    cell: {
        padding: 2,
        margin: 5,
        backgroundColor: '#fff'
    },

    cellHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black',
    },

    cellContents: {
        flexDirection: "row",
    },

    cellImage: {
        borderRadius: 5,
        flex: 1
    },

    cellText: {
        paddingHorizontal: 10,
        flex: 3,
    },
});