import React from "react";
import { Dimensions, View, StyleSheet } from "react-native";
import { Image } from "expo-image";
import { Image as RNImage } from "react-native";

type ScaledImageProps = {
    src: string,
}

type ScaledImageState = {
    width: number,
    height: number
}

class EIScaledImage extends React.Component<ScaledImageProps, ScaledImageState> {
    render() {
        return (
            <View style={styles.imageView}>
                <Image 
                contentFit="contain"
                contentPosition="center"
                source={{ uri: this.props.src }}
                />
            </View>
        )
    }
 }
 
 class RNScaledImage extends React.Component<ScaledImageProps, ScaledImageState> {

    state = { width: 0, height: 0 }

    componentDidMount() {
        RNImage.getSize(this.props.src, (width, height) => {
            let windowWidth = Dimensions.get('window').width * 0.9
                this.setState({
                    width: windowWidth,
                    height: height * (windowWidth / width)
                });
        }, (_error) => { });
    }

    render() {
        return (
                <RNImage
                    resizeMode='contain'
                    source={{ uri: this.props.src }}
                    style={{ height: this.state.height, width: this.state.width }}
                />
        );
    }
}

const styles = StyleSheet.create({

    imageView: {
        backgroundColor: "blue"
    },

    detailImage: {
        flex: 1
    }
})


// const ItemToMeasure = withContentRect('bounds')(
//     ({ measureRef, measure, contentRect }) => (
//       <div ref={measureRef}>
//         Some content here
//         <pre>{JSON.stringify(contentRect, null, 2)}</pre>
//       </div>
//     )
//   )

//   export default ItemToMeasure

  
export default RNScaledImage