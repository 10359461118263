import React from 'react';

import VenueList from '../screens/VenueList'
import VenueDetail from '../screens/VenueDetail'

import { VenueDownload } from '../models/api';
import { RouteProp, NavigationProp } from '@react-navigation/native';

import { createStackNavigator } from '@react-navigation/stack';
import store from '../stores/MeltdownStore';
import { observer } from 'mobx-react';
import { ScrollView } from 'react-native-gesture-handler';

type RootStackParamList = {
    Stages: { venues: VenueDownload[] };
    Stage: { venue: VenueDownload };
};

const Stack = createStackNavigator<RootStackParamList>();

type ListNavProp = NavigationProp<RootStackParamList, 'Stages'>;

type ListProps = {
    navigation: ListNavProp;
};

const List2 = ({ navigation }: ListProps) => <VenueList 
            onSelect={ (venue) => {
                navigation.navigate('Stage', { venue: venue })
            }}
            venues={store.festival!.venues!} />


@observer
class List extends React.Component<ListProps> {
    render() {
        return (
            <VenueList 
            onSelect={ (venue) => {
                this.props.navigation.navigate('Stage', { venue: venue })
            }}
            venues={store.festival!.venues!} />
        )
    }
}

type DetailRouteProp = RouteProp<RootStackParamList, 'Stage'>;

type DetailProps = {
    route: DetailRouteProp;
};


const Detail = ({ route }: DetailProps) => <VenueDetail venue={route.params.venue} />

@observer
class VenueStack extends React.Component {
    render() {
        return (
            <Stack.Navigator screenOptions={{  
                gestureEnabled: false // was causing scrolling bugs on API < 30
                }}>
                <Stack.Screen  
                    name='Stages' 
                    component={List} />
                <Stack.Screen name='Stage' 
                    options={({ route }) => ({ title: route.params.venue.name })} 
                    component={Detail} />
            </Stack.Navigator>)
    }
}

export default VenueStack