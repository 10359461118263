import { observable, action, runInAction, computed, makeObservable, makeAutoObservable } from 'mobx';
import api, { APIError } from '../MUAPI'
import { IOSClientResponse } from '../models/api'
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Config from '../Config';


type StoreCallback = (error: boolean) => void

export class MeltdownStore {

	/**
	 * The selected festival that all artists/venues/events/etc are scoped to, or undefined if there 
	 * is no festival selected.
	 */
	@observable festival?: IOSClientResponse = undefined

	@observable isLoading: Boolean = false

	@computed get isLoaded(): boolean {
		// return false
		return this.festival !== undefined
	}

	@observable error?: string

	constructor() {
		makeObservable(this)
	}

	@action.bound handleApiError(error?: APIError) {
		if (error) {
			switch (error.httpStatusCode) {
				case 500: {
					this.error = `Festivarius received a response that indicates there might be a problem with one of our servers.  Please try again in a short while.   If the problem persists, please report any details to the support team.`
					break
				}
				case undefined: {
					this.error = `Unable to connect to the Festivarius servers.  There are several situations that can cause this problem.   It's possible your internet connection is down, or there could be some internet outages, or the servers are not functioning.  If the problem persists, please report any details to the support team.`
					break
				}
				default: {
					this.error = `Unknown FestivalStore API Error ${error.httpStatusCode}`
					break
				}
			}
			console.error(error);
		}
		else {
			this.error = undefined
			// console.log("no error");

		}

	}

	@action.bound setLoading(loading:boolean) {
		this.isLoading = loading
	}

	@action.bound setFestival(f:IOSClientResponse) {
		this.festival = f
		this.error = undefined

		this.saveToLocalStorage()
	}

	@action.bound loadMeltdown(callback?: StoreCallback) {

		if (this.isLoading) {
			// console.log(`#1 arleady loading festival, not loading, error ${this.error}`)
			return
		}

		// runInAction(() => {
		// console.log("loadMeltdown->runInAction->  error = undefined; isLoading = true")
		this.error = undefined
		this.isLoading = true
		// })

		// setTimeout(() => {
			// console.log("loading meltdown....");
			api.getOne(`/mobile?revision=0&build=${Config.build}&client=android`, IOSClientResponse, (result, error) => {
				// console.log("got response...");
				// console.log("loadMeltdown1 - error: ", error)

				// action("MeltdownStore.loadMeltdown", (something: any) => {
				// runInAction(() => {
					// console.log(something)
					// this.isLoading = false
					this.setLoading(false)
					// console.log("loadMeltdown->runInAction() - error: ", error)
					// console.log("loadMeltdown->runInAction() - result", result)
					// console.log("loadMeltdown->runInAction() - this", this)
					// console.log("loadMeltdown->runInAction() - isLoading", this.isLoading)
					this.handleApiError(error)

					if (result && !error) {
						// console.log("set festival")
						this.setFestival(result as IOSClientResponse)
						// this.festival = result as IOSClientResponse

						// this.saveToLocalStorage()
					}

					if (callback) {
						callback(error ? true : false)
					}

					// console.log("runInAction() done, react should update.")
				// })
				// })
			})
		// }, 1000)

	}

	saveToLocalStorage() {
		try {
			const jsonValue = JSON.stringify(this.festival);
			// console.log("saveToLocalStorage - Saving to local storage")
			// console.log(jsonValue)

			AsyncStorage.setItem('com.autumnblaze.festivalStore', jsonValue).then(
				() => {
					// console.log("saveToLocalStorage success??")
				}
			)
		} catch (e) {
			// console.log(`saveToLocalStorage - failed to save festival ${e}`)
			// saving error
		}

	}

	@action.bound
	loadFromLocalStorage() {
		try {
			//   console.log("loadFromLocalStorage")
			const value = AsyncStorage.getItem('com.autumnblaze.festivalStore').then(
				(jsonString) => {
					// console.log("loadFromLocalStorage jsonString: ", jsonString)

					// make sure we got a string form storage
					if (jsonString !== undefined && jsonString !== null) {
						let json = JSON.parse(jsonString!)
						// console.log("json object")
						// console.log(json)

						json = null

						// make sure it's json
						if (json !== undefined && json !== null) {
							let loaded = IOSClientResponse.fromJS(json)
							// console.log("IOSClientResponse object:")
							// console.log(loaded)

							// make sure we deserialized the model
							if (loaded !== undefined && loaded !== null) {
								// console.log("loadFromLocalStorage -- successfully loaded!")
								this.setFestival(loaded)
								// runInAction(() => {
								// 	this.festival = loaded
								// 	this.error = undefined
								// })
							}
						}
					}
				}
			)
		} catch (e) {
			// error reading value
			// console.log(`loadFromLocalStorage -- failure ${e}`)
		}
	}

	loadMeltdownIfNeeded(callback?: StoreCallback) {
		if (this.isLoading) {
			return
		}

		if (!this.isLoaded) {
			this.loadMeltdown(callback)
		}
		else if (callback) {
			callback(true)
		}
	}


	toJS() {
		return this.isLoaded ? this.festival!.toJS() : "{}";
	}

	static fromJS(array: any) {
		const festivalStore = new MeltdownStore();
		// console.log(array)
		return festivalStore;
	}
}

const store = MeltdownStore.fromJS([])
// console.log('going to loadFromLocalStorage()')
store.loadFromLocalStorage()

export default store
