import React, { ReactElement } from 'react';
import { Text, View, StyleSheet, Dimensions, Image, Falsy, RecursiveArray, RegisteredStyle, TextStyle } from 'react-native';
import { ArtistDownload } from '../models/api';
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";
import { computed } from 'mobx';
import TouchableCell from '../components/TouchableCell'
import MyMarkdown from '../components/Markdown';
import { MixedStyleRecord } from 'react-native-render-html';
import { removeElement, isTag } from 'domutils';
import { tagsStylesList } from '../Styles';
import { observer } from 'mobx-react';

type Optional<T> = T | undefined;

type ArtistListItemProps = {
    onSelect: (artist: ArtistDownload) => void
    artist: ArtistDownload
}

type ArtistListProps = {
    onSelect: (artist: ArtistDownload) => void
    artists: ArtistDownload[]
}
  
  let pNum = 1
  function onElement(element:any) {
    // Remove the first two children of an ol tag.
    if (element.tagName === 'p') {
        // console.log(`found paragraph tag #${pNum}`)

        if (pNum > 1) {
            // console.log(`removing paragraph #${pNum}`)
            removeElement(element)
         }

         pNum++
    //   for (const child of element.children) {
    //     // Children might be text node or comments.
    //     // We don't want to count these.
    //     if (isTag(child) && i < 2) {
    //       removeElement(child);
    //       i++;
    //     }
    //   }
    }
  }
  
  const domVisitors = {
    onElement: onElement
  };
  
  
@observer
export class ArtistListItem extends React.Component<ArtistListItemProps> {

    url(): string {
            return this.props.artist.images[0].url
    }
    
    resetPnum():ReactElement {
        pNum = 1
        return <></>
    }

    render() {
        return (
                <View style={styles.cell}>
                    <TouchableCell onPress={() => { this.props.onSelect(this.props.artist) }}>
                        <View style={styles.cellContents}>
                            {this.props.artist.images && this.props.artist.images[0] ? (
                                <Image style={styles.cellImage} source={{ width: 100, height: 100, uri: this.props.artist.images[0].url }} />
                                ) : (
                                <Image source={require('../../assets/icon-app.png')} style={{ width: 20, height: 20 }} />
                            )}

                            <View style={styles.cellText}>
                               {this.resetPnum()}
                                <Text style={styles.cellHeader}>{this.props.artist.name}</Text>
                                <MyMarkdown domVisitors={domVisitors} tagsStyles={tagsStylesList} source={this.props.artist.bio}/>
                            </View>
                        </View>
                    </TouchableCell >
            </View>)
    }
}

/***
*/
export default class ArtistList extends React.Component<ArtistListProps> {
    width = Dimensions.get("window").width

    dataProvider = new DataProvider((r1, r2) => {
        return r1 !== r2;
    });

    layoutProvider = new LayoutProvider(
        _index => {
            return 0 // all have same type 0
        },
        (_type, dim) => {
            dim.width = this.width;
            dim.height = 110;
        }
    );

    @computed get artists(): ArtistDownload[] {
        return this.props.artists
    }

    rowRenderer(_type: React.ReactText, artist: ArtistDownload, _index: number) {
        // return <Text>Hello</Text>
        return <ArtistListItem 
        artist={artist} 
         onSelect={this.props.onSelect} />
    }

    render() {
        return (
            this.artists ?

                <RecyclerListView
                    disableRecycling={true}
                    layoutProvider={this.layoutProvider}
                    dataProvider={this.dataProvider.cloneWithRows(this.artists)}
                    rowRenderer={this.rowRenderer.bind(this)} />
                :
                <Text>Missing required props</Text>
        )
    }
}

const markdownStyle = {
    body: { 
      fontSize: 15, 
      lineHeight: 16 
    }
  }  

const styles = StyleSheet.create({
    cell: {
        padding: 2,
        margin: 5,
        backgroundColor: '#fff'
    },

    cellHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'left',
        color: 'black'
    },

    cellContents: {
        flexDirection: "row"
    },
    
    cellImage: {
        borderRadius: 5,
        flex: 1
    },

    cellText: {
        paddingHorizontal: 10,
        flex: 3,
        height: 90,
        overflow: "hidden"
    },
});