import React from 'react';
import {
  Text, ScrollView, View, StyleSheet, SafeAreaView, Image,
  Dimensions,
  TouchableOpacity,
  ImageURISource, FlatList, ActivityIndicator
} from 'react-native';

import * as SplashScreen from 'expo-splash-screen';
import { computed, configure, spy } from 'mobx';
import { Observer, observer } from 'mobx-react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Constants from 'expo-constants';
import LineupStack from './src/stacks/LineupStack'
import VenueStack from './src/stacks/VenueStack'
import HomeScreen from './src/screens/Home'
import EventList from './src/screens/ScheduleList'
import ScaledImage from './src/ScaledImage'
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import store, { MeltdownStore } from './src/stores/MeltdownStore';
import { ArtistDownload, FestivalDownload } from './src/models/api';
import MyMarkdown from './src/components/Markdown';
import { MixedStyleRecord } from 'react-native-render-html';
import { tagsStylesDetail } from './src/Styles';
import { Button } from 'react-native-elements';
import Config from './src/Config';
import api from './src/MUAPI';

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();
// SplashScreen.hideAsync();

// configure mobx
// configure( {
//   enforceActions: "always",
//   computedRequiresReaction: true,
//   reactionRequiresObservable: true,
//   observableRequiresReaction: true,
//   safeDescriptors: true,
//   disableErrorBoundaries: true
// })

type MeltdownStoreProps = {
  store: MeltdownStore
}

@observer
class LineupScreen extends React.Component<MeltdownStoreProps> {

  @computed get artists(): ArtistDownload[] {
    let artists = store.festival?.artists

    return artists ? artists : []
  }

  render() {
    // let items = []
    // store.festival?.artists?.forEach((artist) => {
    //   items.push(<ArtistListItem artist={artist} />)
    // })

    return (
      <LineupStack artists={this.artists}
      />
    )
  }
}

// const Tab = createBottomTabNavigator();
const Tab = createMaterialBottomTabNavigator();

class App extends React.Component {
  componentDidMount() {
    // SplashScreen.preventAutoHideAsync();
    store.loadMeltdown()
    SplashScreen.hideAsync()

  }
  render() {
    return (
      <AppContent />
    )
  }
}

@observer
class AppContent extends React.Component {
  render() {
    return (
      store.festival !== undefined
        ? <Main />
        : <View style={styles.container}>

          {
            store.isLoading ?
              <View style={[appStyles.container, appStyles.horizontal]}>
                <ActivityIndicator size="large" />
                <Text style={appStyles.loadText}>Loading...</Text>
              </View> : <></>
          }

          {store.error !== undefined ? <ErrorView /> : <></>}
        </View>
    )
  }
}

@observer
class ErrorView extends React.Component {
  render() {
    return (
      <View>
        <Text style={errorStyles.apiError}>{store.error ? store.error : ""}</Text>
        <View style={errorStyles.fixToText}>
        <Button
            title="Retry"
            onPress={() => {
              // Config.server = "https://felglar.com/api" 
              store.loadMeltdown()
            }} />
          {/* <Button
            title="Fix"
            onPress={() => {
              Config.server = "https://felglar.com/api" 
              store.loadMeltdown()
            }} /> */}
        </View> 
      </View>
    )
  }
}

const appStyles = StyleSheet.create({
  loadText: {
    paddingLeft: 20,
    paddingRight: 20
  },
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  }
});


const errorStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  apiError: {
    marginLeft: 40,
    marginRight: 40,
    fontSize: 17
  },
  fixToText: {
    // width: 200,
    // flexDirection: 'row',
    paddingTop: 50,
    paddingLeft: 100,
    paddingRight: 100,
    justifyContent: 'center',
  },

});


// const ErrorView = () => 
//         <View>
//           <Text style={styles.apiError}>{store.error ? store.error : ""}</Text>
//           <View style={styles.fixToText}>
//             <Button
//               title="Retry"
//               // style={styles.button}
//               onPress={() => {
//                 // Config.server = "https://felglar.com/api" 
//                 store.loadMeltdown()
//                 console.log("pressed")
//               }} />
//           </View>
//         </View>

// const ErrorView = () => {
//   <Observer>
//     {() => {
//       return (
//         <View>
//           <Text style={styles.apiError}>{store.error ? store.error : ""}</Text>
//           <View style={styles.fixToText}>
//             <Button
//               title="Retry"
//               // style={styles.button}
//               onPress={() => {
//                 // Config.server = "https://felglar.com/api" 
//                 store.loadMeltdown()
//                 console.log("pressed")
//               }} />
//           </View>
//         </View>
//       )
//     }
//     }
//   </Observer>
// }

const Home3 = () => <Text>fuck me.....</Text>
const Home2 = () => <View>
  {store.festival === undefined
    ? <Text>Loading....</Text>
    : <HomeScreen festival={store.festival.festival} />
  }
</View>

@observer
class Schedule extends React.Component {
  render() {
    return (
      <ScrollView>
      <EventList venues={store.festival.venues!} events={store.festival.events!} />
      </ScrollView>
    )

  }
}
// const Schedule = () => <ScheduleList venues={store.festival.venues!} events={store.festival.events!} />

@observer
class Home extends React.Component {
  componentDidMount(): void {
    // console.log("Home is mounting.  Have festival?", this.festival() === undefined ? "NO" : "YES")
  }

  festival(): FestivalDownload | undefined {
    if (store.festival) {
      return store.festival!.festival
    }
    else {
      return undefined
    }
  }
  url(): string | undefined {
    let festival = this.festival()
    if (festival === undefined) {
      return undefined
    }
    else {
      return festival.images[0].url
    }
  }
  render() {
    let url = this.url()
    let festival = this.festival()
    
    return (
      <SafeAreaView style={homeStyles.container}>
        <ScrollView style={homeStyles.scrollView}>

          {
            url !== undefined
              ? <ScaledImage src={url} /> : <></>
          }

          {
            festival !== undefined
              ? <MyMarkdown style={markdownStyle} tagsStyles={tagsStylesDetail} source={festival.about} /> :<></>
          }

        </ScrollView>
      </SafeAreaView>

    )
  }
}

const markdownStyle = {
  body: {
    fontSize: 16,
    lineHeight: 24
  }
}

class Main extends React.Component {
  render() {

    return (
      <NavigationContainer>
        <Tab.Navigator
          initialRouteName="Festival"
        >
          <Tab.Screen name="Festival"
            component={Home}
            options={{
              tabBarIcon: ({ color, focused }) => (
                <Image source={require('./assets/icon-home.png')} style={{ width: 20, height: 20 }} />
              ),
            }}
          />
          <Tab.Screen name="Lineup"
            component={LineupScreen}
            options={{
              tabBarIcon: ({ color, focused }) => (
                <Image source={require('./assets/icon-lineup.png')} style={{ width: 20, height: 20 }} />
              ),
            }}
          />
          <Tab.Screen name="Schedule" component={Schedule}
            options={{
              tabBarIcon: ({ color, focused }) => (
                <Image source={require('./assets/icon-calendar.png')} style={{ width: 20, height: 20 }} />
              ),
            }}
          />
          <Tab.Screen name="VenueList" component={VenueStack}
            options={{
              tabBarLabel: "Stages",
              tabBarIcon: ({ color, focused }) => (
                <Image source={require('./assets/icon-venue.png')} style={{ width: 20, height: 20 }} />
              ),
            }}
          />
        </Tab.Navigator>
      </NavigationContainer>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
  },
  container2: {
    flex: 1,
    marginTop: 0,//Constants.statusBarHeight
  },
  scrollView: {
    // backgroundColor: 'pink',
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 18,
  },
  image: {
    width: 200,
    height: 100,
    flex: 1
  },
  buttonContainer: {
    backgroundColor: '#222',
    borderRadius: 5,
    padding: 10,
    margin: 20
  },
  buttonText: {
    fontSize: 20,
    color: '#fff'
  }
});


const homeStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Constants.statusBarHeight,
    paddingTop: 25
  },
  scrollView: {
    // backgroundColor: 'pink',
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 18,
  },
  image: {
    width: 200,
    height: 100,
    flex: 1
  },
});


export default App
