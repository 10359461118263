import React from 'react';
import { Dimensions, StyleSheet, Text } from "react-native"
import RenderHtml, { DomVisitorCallbacks, MixedStyleRecord } from 'react-native-render-html';
import { WebView } from 'react-native-webview';
import showdown from 'showdown';

export interface MarkdownProps {
    source:string;
    style?: StyleSheet.NamedStyles<any>;
    domVisitors?: DomVisitorCallbacks,
    tagsStyles?: MixedStyleRecord
  }

const converter = new showdown.Converter();

// use react-native-webview
export class MyMarkdownWV extends React.Component<MarkdownProps> {
    render() {
        let width = Dimensions.get("window").width
        let html = converter.makeHtml(this.props.source)
        // console.log(`MyMarkdown, width=${width} rendering ${html}`)
        return (
            // <Text>{this.props.source}</Text>
            // <RenderHtml contentWidth={width} source={ { html: html } } />
            <WebView source={{ html: html }} style={{ fontSize: "16px" }} />
        )
    }
}  

// RH = react-native-render-html library
export class MyMarkdownRH extends React.Component<MarkdownProps> {
    render() {
        let width = Dimensions.get("window").width
        let html = converter.makeHtml(this.props.source)
        // console.log(`MyMarkdown, width=${width} rendering ${html}`)
        return (
            // <Text>{this.props.source}</Text>
            <RenderHtml contentWidth={width} domVisitors={this.props.domVisitors} tagsStyles={this.props.tagsStyles} source={ { html: html } } />
        )
    }
}

export default MyMarkdownRH