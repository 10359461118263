import Config from './Config'

import { action, runInAction, observable, computed, makeObservable } from 'mobx';
import { Model } from "./models/Base"
import { Encodeable, Decodable, Codable } from './models/api';
import errorStore from './stores/ErrorStore';

export class APIError extends Error {
    httpStatusCode?: number = undefined
}

export interface APICallback {
    (response?: any, error?: APIError): void
}

/**
 * All rest API calls perform an asynchonous callback named `apiCallback`.
 * 
 * The data will be a JSON object if the call was successful
 *
 * @callback apiCallback
 * @param {Object} data
 * @param {*} error
 */

export interface APIUnauthorized { (response: Response): void }


/** Library for communicating with the rest server */
class MUAPI {

    @observable requestId = 0

    constructor() {
		makeObservable(this)
	}

    /**
     * A callback that is invoked when the api returns 401
     */
    public onUnauthorized?: APIUnauthorized

    @computed get networkActivity(): boolean {
        return this.connectionCount > 0 ? true : false
    }

    @observable connectionCount: number = 0

    @action.bound incrementConnectionCount() {
        this.connectionCount += 1
    }

    @action.bound decrementConnectionCount() {
        this.connectionCount -= 1
    }


    /** Generates a url for the supplied model name 
     * 
     * @param {string} resourceName - the name of the rest resource
     * @param {int} [id = null] - the identifier of the resource, needed or PATCH and DELETE calls
    */
    url(resourceName: string, id?: number) {
        console.assert(resourceName[0] === "/")
        let url = Config.server + resourceName
        if (id) {
            url = url + "/" + id
        }
        return url
    }

    /** Performs a rest call using the supplied method and url, parses the json response
     * and returns the object
     */
    @action.bound 
    restCall(method: string, url: string, body: object = {}, completion: APICallback) {
        this.requestId++

        let headers = new Headers()
        let self = this
    
        headers.append("Accept", "application/json")
    
        if (Config.apiKey !== undefined) {
             headers.append('X-API-Key',Config.apiKey)
        }
    
        let options: RequestInit = {
            method: method,
            headers: headers,
            mode: 'cors'
        }


        let bodyString = ""
        if (body) {
            bodyString = JSON.stringify(body)

            if (bodyString != "{}") {
                headers.append("Content-Type", "application/json")
                options.body = bodyString
            }
        }

        let api = this
        let responseOkay = false

        api.incrementConnectionCount()

        fetch(url, options).then(function (response) {
            responseOkay = response.ok

            // console.log("response.ok",response.ok)

            // content-type: application/json
            // content-type: application/json; charset=utf-8
            let contentType = response.headers.get('Content-Type')
            if (contentType != null) {
            // console.log("content type is ",contentType)
                 contentType = contentType!.split(';')[0]
            }

            // console.log("HERE HERE")

            if (!response.ok) {
                // console.log("response is NOT OKAY")
                let msg = method + " " + url + " -- " + response.statusText + bodyString.slice(0, 1024)
                errorStore.addError(msg)

                if (response.status == 401 && self.onUnauthorized) {
                    self.onUnauthorized(response)
                }
                // console.log('completion scenerio #1',url)

                let error = new APIError(msg)
                error.httpStatusCode = response.status

                try {
                    completion(undefined, error)
                }
                catch(error: any) {
                    // console.log(`#1 caught error during (no response, error) callback; error: ${error}`)
                }
                return null
            }
            else if (contentType === 'application/json') {
                // console.log("returning .json() promise")
                return response.json()
            }
            return null
        }).then(function (json?: any) {
            api.decrementConnectionCount()
            if (json) {
                // console.log("=== START RESPONSE BODY")
                // console.log(JSON.stringify(json))
                // console.log("=== STOP RESPONSE BODY")
                // console.log('completion scenerio #2',url)
                try {
                    completion(json, undefined)
                }
                catch(error: any) {
                    // console.log(`#2 caught error during (success,no error) callback; error: ${error}`)
                }
                return null

            } else if (responseOkay) {
                // no response body which isn't necessarly bad, as long at we got an okay response
                // console.log('completion scenerio #3',url)
                try {
                    completion(undefined, undefined)
                }
                catch(error: any) {
                    // console.log(`#3 caught error during (no error, no response) callback; error: ${error}`)
                }
            }
        }).catch(function (error: TypeError) {
            api.decrementConnectionCount()
            // console.log("=== START REST-CATCH")
            // console.log(error)
            // console.log('=== STOP REST-CATCH')

            let msg = "Catch Error: " + method + " " + url + " -- " + error + bodyString.slice(0, 1024)

            errorStore.addError(msg)

            // console.log('completion scenerio #4',url)
            try {
                completion(undefined, TypeError(msg))
            }
            catch(error: any) {
                // console.log(`#4 caught error during callback; error: ${error}`)
            }

        })
    }

    /**
 * 
 * @param {string} path 
 * @param {Decodable} responseModel 
 * @param {Codable} patchObject 
 * @param {completion:(response?:T[],error?:any) => void} completion 
 */
    @action patchMany<T>(path: string, responseModel: { fromJS(object: any): T }, patchObject: Codable, completion: (response?: T[], error?: any) => void) {
        let body = patchObject.toJS()
        this.restCall("PATCH", this.url(path), body, (result, error) => {
            if (result && !error) {
                runInAction(() => {
                    //FIXME -- better define completion handler?
                    let results = result as Decodable[];
                    let objects = results.map(o => responseModel.fromJS(o))

                    completion(objects, error)
                })
            }
            else {
                completion(undefined, error)
            }
        })
    }



    /**
     * 
     * @param {string} path 
     * @param {Decodable} responseModel 
     * @param {Model} patchObject 
     * @param {Integer} patchObject.id - The identifier of the model being patched, required
     * @param {completion:(response?:T,error?:any) => void} completion 
     */
    @action patchOne<T>(path: string, responseModel: { fromJS(object: any): T }, patchObject: Model, completion: (response?: T, error?: any) => void) {
        // @action patchOne<T>(path:string, responseModel: { fromJS(object:any): T }, patchObject:Model, completion:APICallback) {
        let id = patchObject.id
        let body = patchObject.toJS()
        console.assert(id)
        this.restCall("PATCH", this.url(path, id), body, (result, error) => {
            if (result && !error) {
                runInAction(() => {
                    let response = responseModel.fromJS(result)
                    // responseModel.fromJS(result,patchObject)
                    completion(response, error)
                })
            }
            else {
                completion(undefined, error)
            }
        })
    }

    /** Takes a model name and  */
    createOne<T>(path: string, responseModel: { fromJS(object: any): T }, requestObject: Encodeable, completion: (response?: T, error?: any) => void) {
        let body = requestObject.toJS()

        // a bit hackish, but if there is an id, remove it.   Some strange things with kitura
        if (body.id) {
            delete body.id
        }
        this.restCall("POST", this.url(path), body, (result, error) => {
            if (result && !error) {
                let responseObject = responseModel.fromJS(result)
                completion(responseObject, null)
            }
            else {
                // console.log('createOne failed ' + this.url(path))
                completion(undefined, error)
            }
        })
    }

    /**
     * 
     * @param {string} path 
     * @param {Integer} id - The identifier of the object being deleted, required
     * @param {apiCallback} completion 
     */
    deleteOne(path: string, id: number, completion: APICallback) {
        this.restCall("DELETE", this.url(path, id), undefined, (response, error) => {
            completion(response, error)
        })
    }

    getAll(path: string, responseModel: typeof Decodable, completion: APICallback) {
        this.restCall("GET", this.url(path), undefined, (result, error) => {
            // console.log('getAll  ' + this.url(path), result, error)
            if (result && !error) {
                //FIXME -- better define completion handler?
                let results = result as Decodable[];
                let objects = results.map(o => responseModel.fromJS(o))

                completion(objects, undefined)
            }
            else {
                // console.log('getAll failed ' + this.url(path))
                completion(undefined, error)
            }
        })
    }

    getOne(path: string, responseModel: typeof Decodable, completion: APICallback) {
        this.restCall("GET", this.url(path), undefined, (result, error) => {
            // console.log(`getOne(${this.requestId},'${path}') -> result `,result)
            // console.log(`getOne(${this.requestId},'${path}) -> error`,error)
            if (result && !error) {
                // console.log(`getOne(${this.requestId},'${path}) calling success completion `)
                completion(responseModel.fromJS(result), undefined)
            }
            else {
                // console.log(`getOne(${this.requestId},'${path}') calling FAILURE completion `)
                completion(undefined, error)
            }
        })
    }
}

let api = new MUAPI()

export default api