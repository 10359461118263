/**
 * Kitura Project
 * Generated by Kitura
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import { observable, makeObservable, runInAction } from 'mobx';

export interface Encodeable {
    toJS: () => any
}

export abstract class Decodable {
    static fromJS: (object:any) => any
}

export type Codable = Encodeable & Decodable

// ===============================================
// This file is autogenerated - Please do not edit
// ===============================================

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

export class ObjectSerializer {

    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap[expectedType]) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    return data[discriminatorProperty]; // use the type given in the discriminator
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType));
            }
            return transformedData;
        } else if (type === "Date") {
            return data.toString();
        } else {
            if (enumsMap[type]) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap[type]) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                runInAction( () => {
                    instance[attributeType.name] = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type);
                 })
            }
            return instance;
        }
    }
}

export class ArtistDownload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;
    @observable  bio!: string;
    @observable  billingOrder!: number;
    @observable  images!: Array<ArtistImageDownload>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "bio",
            "baseName": "bio",
            "type": "string"
        },
        {
            "name": "billingOrder",
            "baseName": "billingOrder",
            "type": "number"
        },
        {
            "name": "images",
            "baseName": "images",
            "type": "Array<ArtistImageDownload>"
        }    ];

    static getAttributeTypeMap() {
        return ArtistDownload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"ArtistDownload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"ArtistDownload")
    }
}

export class ArtistImage  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  artistid!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  imageOrder!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "artistid",
            "baseName": "artistid",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "imageOrder",
            "baseName": "imageOrder",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return ArtistImage.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"ArtistImage")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"ArtistImage")
    }
}

export class ArtistImageDownload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  url!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return ArtistImageDownload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"ArtistImageDownload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"ArtistImageDownload")
    }
}

export class ArtistImageUpload  implements Encodeable, Decodable {
    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  artistId!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  data!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "artistId",
            "baseName": "artistId",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return ArtistImageUpload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"ArtistImageUpload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"ArtistImageUpload")
    }
}

export class ArtistPatch  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;
    @observable  bio!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "bio",
            "baseName": "bio",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return ArtistPatch.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"ArtistPatch")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"ArtistPatch")
    }
}

export class ArtistPost  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  name!: string;
    @observable  bio!: string;
    @observable  festivalId!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "bio",
            "baseName": "bio",
            "type": "string"
        },
        {
            "name": "festivalId",
            "baseName": "festivalId",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return ArtistPost.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"ArtistPost")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"ArtistPost")
    }
}

export class DBArtist  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  name!: string;
    @observable  bio!: string;
    @observable  festivalid!: number;
    @observable  billingOrder!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "bio",
            "baseName": "bio",
            "type": "string"
        },
        {
            "name": "festivalid",
            "baseName": "festivalid",
            "type": "number"
        },
        {
            "name": "billingOrder",
            "baseName": "billingOrder",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return DBArtist.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"DBArtist")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"DBArtist")
    }
}

export class DBFestival  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  name!: string;
    @observable  about!: string;
    @observable  startDate!: number;
    @observable  endDate!: number;
    @observable  locationTitle!: string;
    @observable  locationSubtitle!: string;
    @observable  locationLatitude!: number;
    @observable  locationLongitude!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        },
        {
            "name": "startDate",
            "baseName": "startDate",
            "type": "number"
        },
        {
            "name": "endDate",
            "baseName": "endDate",
            "type": "number"
        },
        {
            "name": "locationTitle",
            "baseName": "locationTitle",
            "type": "string"
        },
        {
            "name": "locationSubtitle",
            "baseName": "locationSubtitle",
            "type": "string"
        },
        {
            "name": "locationLatitude",
            "baseName": "locationLatitude",
            "type": "number"
        },
        {
            "name": "locationLongitude",
            "baseName": "locationLongitude",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return DBFestival.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"DBFestival")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"DBFestival")
    }
}

export class DBFestivalImage  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  festivalid!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  imageOrder!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "festivalid",
            "baseName": "festivalid",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "imageOrder",
            "baseName": "imageOrder",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return DBFestivalImage.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"DBFestivalImage")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"DBFestivalImage")
    }
}

export class DBVenue  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  name!: string;
    @observable  bio!: string;
    @observable  billingOrder!: number;
    @observable  festivalid!: number;
    @observable  locationTitle!: string;
    @observable  locationSubTitle!: string;
    @observable  latitude!: number;
    @observable  longitude!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "bio",
            "baseName": "bio",
            "type": "string"
        },
        {
            "name": "billingOrder",
            "baseName": "billingOrder",
            "type": "number"
        },
        {
            "name": "festivalid",
            "baseName": "festivalid",
            "type": "number"
        },
        {
            "name": "locationTitle",
            "baseName": "locationTitle",
            "type": "string"
        },
        {
            "name": "locationSubTitle",
            "baseName": "locationSubTitle",
            "type": "string"
        },
        {
            "name": "latitude",
            "baseName": "latitude",
            "type": "number"
        },
        {
            "name": "longitude",
            "baseName": "longitude",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return DBVenue.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"DBVenue")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"DBVenue")
    }
}

export class DBVenueImage  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  venueid!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  imageOrder!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "venueid",
            "baseName": "venueid",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "imageOrder",
            "baseName": "imageOrder",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return DBVenueImage.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"DBVenueImage")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"DBVenueImage")
    }
}

export class EventDownload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;
    @observable  about!: string;
    @observable  venue?: VenueDownload;
    @observable  artist?: ArtistDownload;
    @observable  eventType!: EventTypeResponse;
    @observable  start!: string;
    @observable  duration!: number;
    @observable  location?: Location;
    @observable  images!: Array<EventImageDownload>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        },
        {
            "name": "venue",
            "baseName": "venue",
            "type": "VenueDownload"
        },
        {
            "name": "artist",
            "baseName": "artist",
            "type": "ArtistDownload"
        },
        {
            "name": "eventType",
            "baseName": "eventType",
            "type": "EventTypeResponse"
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "string"
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "number"
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "Location"
        },
        {
            "name": "images",
            "baseName": "images",
            "type": "Array<EventImageDownload>"
        }    ];

    static getAttributeTypeMap() {
        return EventDownload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"EventDownload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"EventDownload")
    }
}

export class EventImage  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  eventId!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  imageOrder!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "eventId",
            "baseName": "eventId",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "imageOrder",
            "baseName": "imageOrder",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return EventImage.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"EventImage")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"EventImage")
    }
}

export class EventImageDownload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  url!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return EventImageDownload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"EventImageDownload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"EventImageDownload")
    }
}

export class EventImageUpload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  eventId!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  data!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "eventId",
            "baseName": "eventId",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return EventImageUpload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"EventImageUpload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"EventImageUpload")
    }
}

export class EventPatch  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;
    @observable  about!: string;
    @observable  start!: string;
    @observable  finish!: string;
    @observable  venueId?: number;
    @observable  artistId?: number;
    @observable  eventTypeId!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "string"
        },
        {
            "name": "finish",
            "baseName": "finish",
            "type": "string"
        },
        {
            "name": "venueId",
            "baseName": "venueId",
            "type": "number"
        },
        {
            "name": "artistId",
            "baseName": "artistId",
            "type": "number"
        },
        {
            "name": "eventTypeId",
            "baseName": "eventTypeId",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return EventPatch.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"EventPatch")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"EventPatch")
    }
}

export class EventPost  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  name!: string;
    @observable  about!: string;
    @observable  start!: string;
    @observable  finish!: string;
    @observable  festivalId!: number;
    @observable  eventTypeId!: number;
    @observable  venueId?: number;
    @observable  artistId?: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "string"
        },
        {
            "name": "finish",
            "baseName": "finish",
            "type": "string"
        },
        {
            "name": "festivalId",
            "baseName": "festivalId",
            "type": "number"
        },
        {
            "name": "eventTypeId",
            "baseName": "eventTypeId",
            "type": "number"
        },
        {
            "name": "venueId",
            "baseName": "venueId",
            "type": "number"
        },
        {
            "name": "artistId",
            "baseName": "artistId",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return EventPost.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"EventPost")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"EventPost")
    }
}

export class EventTypePost  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  name!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return EventTypePost.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"EventTypePost")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"EventTypePost")
    }
}

export class EventTypeResponse  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return EventTypeResponse.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"EventTypeResponse")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"EventTypeResponse")
    }
}

export class FestivalDownload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;
    @observable  about!: string;
    @observable  startDate!: string;
    @observable  endDate!: string;
    @observable  location!: Location;
    @observable  images!: Array<FestivalImageDownload>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        },
        {
            "name": "startDate",
            "baseName": "startDate",
            "type": "string"
        },
        {
            "name": "endDate",
            "baseName": "endDate",
            "type": "string"
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "Location"
        },
        {
            "name": "images",
            "baseName": "images",
            "type": "Array<FestivalImageDownload>"
        }    ];

    static getAttributeTypeMap() {
        return FestivalDownload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"FestivalDownload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"FestivalDownload")
    }
}

export class FestivalImageDownload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  url!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return FestivalImageDownload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"FestivalImageDownload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"FestivalImageDownload")
    }
}

export class FestivalImageUpload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  festivalId!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  data!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "festivalId",
            "baseName": "festivalId",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return FestivalImageUpload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"FestivalImageUpload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"FestivalImageUpload")
    }
}

export class FestivalPatch  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;
    @observable  about!: string;
    @observable  startDate!: number;
    @observable  endDate!: number;
    @observable  location?: Location;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        },
        {
            "name": "startDate",
            "baseName": "startDate",
            "type": "number"
        },
        {
            "name": "endDate",
            "baseName": "endDate",
            "type": "number"
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "Location"
        }    ];

    static getAttributeTypeMap() {
        return FestivalPatch.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"FestivalPatch")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"FestivalPatch")
    }
}

export class FestivalPost  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  name!: string;
    @observable  about!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return FestivalPost.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"FestivalPost")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"FestivalPost")
    }
}

export class IOSClientEventResponse  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  title!: string;
    @observable  about!: string;
    @observable  venueId?: number;
    @observable  artistId?: number;
    @observable  eventType!: EventTypeResponse;
    @observable  start!: string;
    @observable  finish!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        },
        {
            "name": "venueId",
            "baseName": "venueId",
            "type": "number"
        },
        {
            "name": "artistId",
            "baseName": "artistId",
            "type": "number"
        },
        {
            "name": "eventType",
            "baseName": "eventType",
            "type": "EventTypeResponse"
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "string"
        },
        {
            "name": "finish",
            "baseName": "finish",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return IOSClientEventResponse.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"IOSClientEventResponse")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"IOSClientEventResponse")
    }
}

export class IOSClientResponse  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  festival!: FestivalDownload;
    @observable  artists?: Array<ArtistDownload>;
    @observable  venues?: Array<VenueDownload>;
    @observable  events?: Array<IOSClientEventResponse>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "festival",
            "baseName": "festival",
            "type": "FestivalDownload"
        },
        {
            "name": "artists",
            "baseName": "artists",
            "type": "Array<ArtistDownload>"
        },
        {
            "name": "venues",
            "baseName": "venues",
            "type": "Array<VenueDownload>"
        },
        {
            "name": "events",
            "baseName": "events",
            "type": "Array<IOSClientEventResponse>"
        }    ];

    static getAttributeTypeMap() {
        return IOSClientResponse.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"IOSClientResponse")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"IOSClientResponse")
    }
}

export class Location  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  title!: string;
    @observable  subtitle!: string;
    @observable  latitude!: number;
    @observable  longitude!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "subtitle",
            "baseName": "subtitle",
            "type": "string"
        },
        {
            "name": "latitude",
            "baseName": "latitude",
            "type": "number"
        },
        {
            "name": "longitude",
            "baseName": "longitude",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return Location.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"Location")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"Location")
    }
}

export class LoginRequest  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  username!: string;
    @observable  password!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "username",
            "baseName": "username",
            "type": "string"
        },
        {
            "name": "password",
            "baseName": "password",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return LoginRequest.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"LoginRequest")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"LoginRequest")
    }
}

export class LoginResponse  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  success!: boolean;
    @observable  token?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "success",
            "baseName": "success",
            "type": "boolean"
        },
        {
            "name": "token",
            "baseName": "token",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return LoginResponse.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"LoginResponse")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"LoginResponse")
    }
}

export class PatchOrderRequest  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  childrenIds!: Array<number>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "childrenIds",
            "baseName": "childrenIds",
            "type": "Array<number>"
        }    ];

    static getAttributeTypeMap() {
        return PatchOrderRequest.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"PatchOrderRequest")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"PatchOrderRequest")
    }
}

export class SetPatch  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  venueId!: number;
    @observable  artistId!: number;
    @observable  start!: number;
    @observable  duration!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "venueId",
            "baseName": "venueId",
            "type": "number"
        },
        {
            "name": "artistId",
            "baseName": "artistId",
            "type": "number"
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "number"
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return SetPatch.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"SetPatch")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"SetPatch")
    }
}

export class SetPost  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  venueId!: number;
    @observable  artistId!: number;
    @observable  festivalId!: number;
    @observable  start!: number;
    @observable  duration!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "venueId",
            "baseName": "venueId",
            "type": "number"
        },
        {
            "name": "artistId",
            "baseName": "artistId",
            "type": "number"
        },
        {
            "name": "festivalId",
            "baseName": "festivalId",
            "type": "number"
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "number"
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return SetPost.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"SetPost")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"SetPost")
    }
}

export class SetResponse  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  festivalId!: number;
    @observable  venueId!: number;
    @observable  artistId!: number;
    @observable  start!: number;
    @observable  duration!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "festivalId",
            "baseName": "festivalId",
            "type": "number"
        },
        {
            "name": "venueId",
            "baseName": "venueId",
            "type": "number"
        },
        {
            "name": "artistId",
            "baseName": "artistId",
            "type": "number"
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "number"
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return SetResponse.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"SetResponse")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"SetResponse")
    }
}

export class Status  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  status!: string;
    @observable  details!: Array<string>;
    @observable  timestamp!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "status",
            "baseName": "status",
            "type": "string"
        },
        {
            "name": "details",
            "baseName": "details",
            "type": "Array<string>"
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return Status.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"Status")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"Status")
    }
}

export class VenueDownload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;
    @observable  bio!: string;
    @observable  billingOrder!: number;
    @observable  location?: Location;
    @observable  images!: Array<VenueImageDownload>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "bio",
            "baseName": "bio",
            "type": "string"
        },
        {
            "name": "billingOrder",
            "baseName": "billingOrder",
            "type": "number"
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "Location"
        },
        {
            "name": "images",
            "baseName": "images",
            "type": "Array<VenueImageDownload>"
        }    ];

    static getAttributeTypeMap() {
        return VenueDownload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"VenueDownload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"VenueDownload")
    }
}

export class VenueImageDownload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  url!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "url",
            "baseName": "url",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return VenueImageDownload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"VenueImageDownload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"VenueImageDownload")
    }
}

export class VenueImageUpload  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id?: number;
    @observable  venueId!: number;
    @observable  title?: string;
    @observable  description?: string;
    @observable  contentType!: string;
    @observable  data!: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "venueId",
            "baseName": "venueId",
            "type": "number"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contentType",
            "baseName": "contentType",
            "type": "string"
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return VenueImageUpload.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"VenueImageUpload")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"VenueImageUpload")
    }
}

export class VenuePatch  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  id!: number;
    @observable  name!: string;
    @observable  bio!: string;
    @observable  location?: Location;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "bio",
            "baseName": "bio",
            "type": "string"
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "Location"
        }    ];

    static getAttributeTypeMap() {
        return VenuePatch.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"VenuePatch")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"VenuePatch")
    }
}

export class VenuePost  implements Encodeable, Decodable {

    constructor() {
		makeObservable(this)
	}

    @observable  name!: string;
    @observable  about!: string;
    @observable  festivalId!: number;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "about",
            "baseName": "about",
            "type": "string"
        },
        {
            "name": "festivalId",
            "baseName": "festivalId",
            "type": "number"
        }    ];

    static getAttributeTypeMap() {
        return VenuePost.attributeTypeMap;
    }

    static fromJS(object:any) {
        return ObjectSerializer.deserialize(object,"VenuePost")
    }

    toJS() {
        return ObjectSerializer.serialize(this,"VenuePost")
    }
}


let enumsMap: {[index: string]: any} = {
}

let typeMap: {[index: string]: any} = {
    "ArtistDownload": ArtistDownload,
    "ArtistImage": ArtistImage,
    "ArtistImageDownload": ArtistImageDownload,
    "ArtistImageUpload": ArtistImageUpload,
    "ArtistPatch": ArtistPatch,
    "ArtistPost": ArtistPost,
    "DBArtist": DBArtist,
    "DBFestival": DBFestival,
    "DBFestivalImage": DBFestivalImage,
    "DBVenue": DBVenue,
    "DBVenueImage": DBVenueImage,
    "EventDownload": EventDownload,
    "EventImage": EventImage,
    "EventImageDownload": EventImageDownload,
    "EventImageUpload": EventImageUpload,
    "EventPatch": EventPatch,
    "EventPost": EventPost,
    "EventTypePost": EventTypePost,
    "EventTypeResponse": EventTypeResponse,
    "FestivalDownload": FestivalDownload,
    "FestivalImageDownload": FestivalImageDownload,
    "FestivalImageUpload": FestivalImageUpload,
    "FestivalPatch": FestivalPatch,
    "FestivalPost": FestivalPost,
    "IOSClientEventResponse": IOSClientEventResponse,
    "IOSClientResponse": IOSClientResponse,
    "Location": Location,
    "LoginRequest": LoginRequest,
    "LoginResponse": LoginResponse,
    "PatchOrderRequest": PatchOrderRequest,
    "SetPatch": SetPatch,
    "SetPost": SetPost,
    "SetResponse": SetResponse,
    "Status": Status,
    "VenueDownload": VenueDownload,
    "VenueImageDownload": VenueImageDownload,
    "VenueImageUpload": VenueImageUpload,
    "VenuePatch": VenuePatch,
    "VenuePost": VenuePost,
}

export function addTypeMap(name:string, modelClass:any) {
    typeMap[name] = modelClass
}
