import React from 'react';
import { View, StyleSheet, Dimensions, SectionList, SafeAreaView } from 'react-native';
import { Text, ButtonGroup } from 'react-native-elements'
import { IOSClientEventResponse, VenueDownload } from '../models/api';
import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";
import { computed } from 'mobx';
import Constants from 'expo-constants';
import moment2, { Moment } from 'moment-timezone';
import { extendMoment, DateRange } from 'moment-range';
import TouchableCell from '../components/TouchableCell'
import store from '../stores/MeltdownStore';
import { observer } from 'mobx-react';

const moment = extendMoment(moment2);

moment.tz.setDefault('America/Denver')

type EventListItemProps = {
    onSelect?: (event: IOSClientEventResponse) => void
    event: IOSClientEventResponse
    venue: VenueDownload
    tz: string
}

type EventListProps = {
    onSelect?: (event: IOSClientEventResponse) => void
    events: IOSClientEventResponse[]
    venues: VenueDownload[]
}

type ScheduleData = {
    venue: VenueDownload,
    data: IOSClientEventResponse[]
}

@observer
class EventListItem extends React.Component<EventListItemProps> {
    @computed get eventTime(): string {
        
        let start = moment.tz(this.props.event.start,this.props.tz)
        let end = moment.tz(this.props.event.finish,this.props.tz)

        return start.format('LT') + ' - ' + end.format('LT');
    }

    render() {
        return (
            <View>
                <View style={styles.cell}>
                    <TouchableCell
                        onPress={() => { console.log("onpress") }}
                    >
                        <View style={styles.cellContents}>
                            <View style={styles.cellText}>
                                <Text style={styles.cellHeader}>{this.props.event.title}</Text>
                                <Text numberOfLines={2} ellipsizeMode='tail'>{this.eventTime}</Text>
                            </View>
                        </View>
                    </TouchableCell>
                </View>
            </View>)
    }
}

/***
*/
@observer
export default class EventList extends React.Component<EventListProps> {
    width = Dimensions.get("window").width

    state = {
        selectedIndex: 0,
        selectedDayIndex: 0
    }

    venueWithId = (_id?: number) => {
        return this.props.venues.find(v => v.id == _id)
    }


    dataProvider = new DataProvider((r1, r2) => {
        return r1 !== r2;
    });

    layoutProvider = new LayoutProvider(
        _index => {
            return 0 // all have same type 0
        },
        (_type, dim) => {
            dim.width = this.width;
            dim.height = 60;
        }
    );

    rowRenderer(_type: React.ReactText, event: IOSClientEventResponse, _index: number) {
        return <EventListItem
            event={event}
            venue={this.venueWithId(event.venueId)!}
            onSelect={this.props.onSelect} />
    }

    @computed get data(): ScheduleData[] {
        // console.log('@computed data()')
        let schedule: ScheduleData[] = []
        let selectedDay = this.days[this.state.selectedIndex].format("MM/DD/YYYY")
        // console.log(`selected day is ${this.days[this.state.selectedIndex]}, ${selectedDay}`)
        store.festival?.venues?.forEach((venue) => {
            let venueEvents = store.festival?.events?.filter((event) => event.venueId === venue.id)

            let forDate = venueEvents?.filter((event) => {
                let start = moment(event.start).tz("America/Denver").format("MM/DD/YYYY")
                let same = start == selectedDay
                // console.log(`event.start ${event.title} ${event.start} moment ${start} same day: ${same}`)
                return same
            })

            if (forDate && forDate?.length > 0) {
            schedule.push({ venue: venue, data: forDate! })
        }
        })

        // console.log(data)

        return schedule
    }

    @computed get days():Moment[] {
        let start = moment.utc(store.festival?.festival.startDate)
        let end = moment.utc(store.festival?.festival.endDate)
        // console.log("startDate",store.festival?.festival.startDate)
        // console.log("endDate",store.festival?.festival.endDate)
        var days = Array.from(moment.range(start, end).by('days'));

        // console.log("days:")
        // console.log(days)
        return days
    }

    updateIndex = (selectedIndex: number) => {
        // console.log('update index')
        this.setState({ selectedIndex })
    }

    render() {
        const buttons = this.days.map((day) => moment.utc(day).format('dddd'))
        // console.log("buttons: ")
        // console.log(buttons)
        const { selectedIndex } = this.state

        return (
            this.props.events ?

                <SafeAreaView style={styles.container}>

                    <View>
                        <ButtonGroup
                            onPress={this.updateIndex}

                            selectedIndex={selectedIndex}
                            buttons={buttons}
                            containerStyle={{ height: 50 }}
                        />

                        <SectionList
                        style={ styles.sectionList }
                            sections={this.data}
                            renderSectionHeader={({ section }) => <Text h4 style={styles.sectionHeader}>{section.venue.name}</Text>} // was <Text h3></Text>
                            // renderSectionHeader={({ section }) => <Text style={styles.sectionHeader}>{section.venue.name}</Text>} // was <Text h3></Text>
                            renderItem={({ item }) => <EventListItem tz="America/Denver" venue={this.venueWithId(item.venueId)!} event={item} />}
                        />
                    </View>
                </SafeAreaView>
                :
                <Text>Missing required props</Text>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: "green",
        marginTop: Constants.statusBarHeight,
        paddingTop: 25,
        paddingBottom: 75 // last cell was hiding behind the tab bar, maybe margin in better?
    },

    sectionHeader: {
        paddingLeft: 5,
        color: '#777',

        paddingTop: 15
    },

    sectionList: {
    },

    // The entire cell
    cell: {
        padding: 2,
        margin: 5,
        minHeight: 60, // set a min height, events with longer names that wrap to a 2nd line won't fit in 60px, so setting only minHeight allows the cell to grow
        paddingBottom: 10,
        paddingTop: 10,
        backgroundColor: '#fff'
    },

    cellHeader: {
        fontSize: 17,
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#333',
    },

    cellContents: {
        flexDirection: "row",
    },

    cellImage: {
        borderRadius: 5,
        flex: 1
    },

    cellText: {
        paddingHorizontal: 10,
        flex: 3,
    },
});