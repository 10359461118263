import React from 'react';
import { Platform } from 'react-native'
import ArtistList from '../screens/ArtistList'
import ArtistDetail from '../screens/ArtistDetail'

import { ArtistDownload } from '../models/api';
import { RouteProp, NavigationProp } from '@react-navigation/native';

import { createStackNavigator } from '@react-navigation/stack';
import store from '../stores/MeltdownStore';
import { observer } from 'mobx-react';

type RootStackParamList = {
    Artists: { artists: ArtistDownload[] };
    Artist: { artist: ArtistDownload };
};

const Stack = createStackNavigator<RootStackParamList>();

type ListNavigationProp = NavigationProp<RootStackParamList, 'Artists'>;

type ListProps = {
    navigation: ListNavigationProp;
};

const List = ({ navigation }: ListProps) => <ArtistList 
            onSelect={ (artist) => {
                navigation.navigate('Artist', { artist: artist })
            }}
            artists={store.festival!.artists!} />


type DetailRouteProp = RouteProp<RootStackParamList, 'Artist'>;

type DetailProps = {
    route: DetailRouteProp;
};


const Detail = ({ route }: DetailProps) => <ArtistDetail artist={route.params.artist} />

@observer
class ArtistStack extends React.Component {

    render() {
        return (
            <Stack.Navigator
            screenOptions={{
                gestureEnabled: false // was causing scrolling bugs on API < 30
              }}>
                <Stack.Screen
                    name='Artists'
                    component={List} />
                <Stack.Screen name='Artist'
                    options={({ route }) => ({ title: route.params.artist.name })}
                    component={Detail} />
            </Stack.Navigator>)
    }
}

export default ArtistStack