import React  from 'react';
import { Text, StyleSheet, ScrollView } from 'react-native';
import ScaledImage from '../ScaledImage'
import { VenueDownload } from '../models/api';
import { computed } from 'mobx';
import MyMarkdown from '../components/Markdown';
import { tagsStylesDetail } from '../Styles';
import { observer } from 'mobx-react';

// react-native-web-maps (webpack alias) doesn't export Marker the same way react-native-maps does.
// const MapMarker = (Platform.OS === 'web') ? MapView.Marker : Marker

type VenueProps = {
    venue: VenueDownload
}

@observer
export default class VenueDetail extends React.Component<VenueProps> {

    // markerRef = createRef<Marker>();

    @computed get hasLocationData() {
        return this.props.venue.location ? true : false
    }

    // @computed get coordinate(): LatLng {
    //     let location = this.props.venue.location!

    //     return {
    //         latitude: location.latitude,
    //         longitude: location.longitude
    //     }
    // }

    // @computed get region(): Region {
    //     return {
    //         ...this.coordinate,
    //         latitudeDelta: 0.000922,
    //         longitudeDelta: 0.000421
    //     }
    // }

    // onRegionChangeComplete = () => {
    //     if (this.markerRef && this.markerRef.current && this.markerRef.current!.showCallout) {
    //       this.markerRef.current!.showCallout();
    //     }
    //   };
    
    render() {
        return (
            <ScrollView style={styles.scrollView}>
                <ScaledImage src={this.props.venue.images[0].url} />
                <Text style={styles.detailHeader}>{this.props.venue.name}</Text>
                <MyMarkdown tagsStyles={tagsStylesDetail} source={this.props.venue.bio}/>
                {/* <View style={styles.container}>

                    <MapView
                        onRegionChangeComplete={this.onRegionChangeComplete}
                        style={styles.mapStyle}
                        initialRegion={this.region}
                    >
                        <MapMarker ref={this.markerRef} 
                                coordinate={this.coordinate} title={this.props.venue.location?.title} description={this.props.venue.location?.subtitle} />
                    </MapView>
                </View> */}

            </ScrollView>
        )
    }
}

const markdownStyle = {
    body: {
        fontSize: 16,
        lineHeight: 24
    }
}

const styles = StyleSheet.create({

    scrollView: {
        paddingHorizontal: 20,
    },

    detailImage: {
        flex: 1
    },

    detailHeader: {
        fontSize: 27,
        fontWeight: "bold"
    },

    detailBio: {
        lineHeight: 24,
        fontSize: 16,
        paddingBottom: 48

    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mapStyle: {
        width: 300,
        height: 300,
    },
});
